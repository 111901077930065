import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalFooter,
  Button,
  ModalBody,
  Table,
  Input,
} from 'reactstrap';

import './CredentialRequestDetailsModal.css';
import closeIcon from './../../../assets/closeIcon.png';
import { CredentialRequestDto } from '../../../domain/credentialView';
import { Popup } from '../../../components/Popup/Popup';
import { I18n } from '../../../i18n/i18n';
import {
  getDevicesList,
  ParsedBackendError,
  updateCredentialRequest,
  rejectVerifiableCredential,
} from '../../../services/vidcredentials';
import { Device } from '../../../domain/devices';
import { DevicesList } from '../DevicesList/DevicesList';
import { CredentialRequestDetailsView } from '../../../domain/credentialRequestView';
import { transformCamelCase } from '../../Credentials/CredentialDetailsModal/helpers/StringHelpers';

interface Props {
  credential: CredentialRequestDetailsView;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const CredentialRequestDetailsModal = (props: Props) => {
  const [devicesList, setDevicesList] = useState<Device[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isEditMode] = useState(true);
  const [showDevicesList, setShowDevicesList] = useState(false);
  const [approvedOrder, setApprovedOrder] = useState(false);
  const [loadingDocVerification, setLoadingVerification] = useState(false);
  const [docStatus, setDocStatus] = useState('');
  const [selectedDevice, selectDevice] = useState('');
  const [rejectedCredentialRequest, setRejectedCredentialRequest] = useState<
    string | null
  >(null);
  const [isF2F, setIsF2F] = useState(false);

  const [formValue, setFormValue] = useState<CredentialRequestDto>({
    payload: {},
    language: '',
    email: '',
    identityVerificationMethod: {
      type: '',
    },
  });

  const handlePayloadChange = (e: any) => {
    const { name, value } = e.target;

    setFormValue({
      ...formValue,
      payload: {
        ...formValue.payload,
        [name]: value,
      },
    });
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === 'userName' && formValue.identityVerificationMethod.userData) {
      formValue.identityVerificationMethod.userData.userName = value;
      setFormValue({ ...formValue });
      return;
    }
    if (
      name === 'userLegalId' &&
      formValue.identityVerificationMethod.userData
    ) {
      formValue.identityVerificationMethod.userData.userLegalId = value;
      setFormValue({ ...formValue });
      return;
    }
    if (
      name === 'requested-info-email' &&
      formValue.identityVerificationMethod.requestedInfo
    ) {
      formValue.identityVerificationMethod.requestedInfo[0].value = value;
      setFormValue({ ...formValue });
      return;
    }
    setFormValue({
      ...formValue,
      [name]: value,
    });
  };

  const updateCredential = async () => {
    if (selectedDevice != '') {
      const body = formValue;
      if (body.identityVerificationMethod.userData) {
        body.identityVerificationMethod.userData.deviceId = selectedDevice;
      }
      try {
        await updateCredentialRequest(props.credential.id, body);
        setShowDevicesList(false);
        setApprovedOrder(true);
        // setSuccessMessage(I18n.t('form.credentialUpdated'));
        setErrorMessage('');
      } catch (e) {
        setApprovedOrder(true);
        setErrorMessage((e as ParsedBackendError).title);
        setSuccessMessage('');
      }
    }
  };

  const updateCredentialWithoutDevice = async () => {
    try {
      await updateCredentialRequest(props.credential.id, formValue);
      setShowDevicesList(false);
      setApprovedOrder(true);
      // setSuccessMessage(I18n.t('form.credentialUpdated'));
      setErrorMessage('');
    } catch (e) {
      setApprovedOrder(true);
      setErrorMessage((e as ParsedBackendError).title);
      setSuccessMessage('');
    }
  };

  const rejectCredential = async (id: string) => {
    try {
      await rejectVerifiableCredential(id);
      setSuccessMessage(I18n.t('details.rejectSuccess'));
      setErrorMessage('');
    } catch (error) {
      setErrorMessage((error as ParsedBackendError).title);
      setSuccessMessage('');
    }

    setRejectedCredentialRequest(null);
  };

  useEffect(() => {
    setIsF2F(
      props.credential.identityVerificationMethod?.type === 'F2F' || false,
    );
    setApprovedOrder(props.credential.status === 'APPROVED');
    if (props.isOpen) {
      const newFormValue: CredentialRequestDto = {
        payload: props.credential.payload || {},
        language: props.credential.language || '',
        email: props.credential.holder,
        identityVerificationMethod: {
          type: props.credential.identityVerificationMethod.type || '',
        },
      };

      if (props.credential.identityVerificationMethod.userData) {
        newFormValue.identityVerificationMethod.userData = {
          userName:
            props.credential.identityVerificationMethod.userData.userName || '',
          userLegalId:
            props.credential.identityVerificationMethod.userData.userLegalId ||
            '',
          deviceId:
            props.credential.identityVerificationMethod.userData.deviceId || '',
        };
      }
      if (
        props.credential.identityVerificationMethod &&
        props.credential.identityVerificationMethod.requestedInfo
      ) {
        newFormValue.identityVerificationMethod.requestedInfo = [
          {
            value: props.credential.identityVerificationMethod.requestedInfo
              ? props.credential.identityVerificationMethod.requestedInfo[0]
                  .value
              : '',
            path: props.credential.identityVerificationMethod.requestedInfo
              ? props.credential.identityVerificationMethod.requestedInfo[0]
                  .path
              : '',
          },
        ];
      }
      setFormValue(newFormValue);
    }
  }, [props.isOpen]);

  useEffect(() => {
    const fetchDevices = async () => {
      const data = await getDevicesList();
      setDevicesList(data as Device[]);
    };

    fetchDevices();
  }, []);

  const isIsoDate = (str: any): boolean => {
    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
    const date = new Date(str);
    return (
      date instanceof Date && !Number.isNaN(date) && date.toISOString() === str
    );
  };

  const displayPayload = (payload: any) => {
    return (
      payload &&
      Object.keys(payload).map((key) => {
        const isDate = isIsoDate(payload[key]);

        const inputElement = (
          <Input
            type={isDate ? 'date' : 'text'}
            value={formValue.payload[key]}
            name={key}
            onChange={handlePayloadChange}
          />
        );

        const textLabel = isDate ? formatDate(payload[key]) : payload[key];

        return (
          <tr key={key}>
            <th scope="row">{key}:</th>
            <td data-testid="payload">
              {isEditMode ? inputElement : textLabel}
            </td>
          </tr>
        );
      })
    );
  };

  const closeModal = (e: any) => {
    e.preventDefault();
    setDocStatus('');
    setShowDevicesList(false);
    setApprovedOrder(false);
    props.setIsOpen(false);
  };

  const formatDate = (date: string | undefined): string => {
    if (!date) {
      return I18n.t('table.notAvailable');
    }
    const formattedDate = new Date(date);
    return formattedDate.toLocaleString();
  };

  const goToCredentialsOrderList = async () => {
    props.setIsOpen(false);
  };

  return (
    <Modal isOpen={props.isOpen} centered={true} fullscreen="lg" size="lg">
      <ModalHeader
        close={
          <a href="#" onClick={(e) => closeModal(e)}>
            <img src={closeIcon} className="iconClose" />
          </a>
        }
        toggle={() => props.setIsOpen(false)}
        className="modalHeader"
      >
        {docStatus === 'Signed'
          ? I18n.t('details.signed')
          : approvedOrder
          ? I18n.t('details.credentialRequestApproved')
          : showDevicesList
          ? I18n.t('details.credentialIssuanceDocumentSignature')
          : I18n.t('details.credentialRequestDetails')}
      </ModalHeader>
      <ModalBody className="modalBody">
        {showDevicesList ? (
          <DevicesList
            selectDevice={selectDevice}
            selectedDevice={selectedDevice}
            devicesList={devicesList}
          />
        ) : approvedOrder ? (
          <>{I18n.t('details.credentialOrderCreatedFromRequest')}</>
        ) : (
          <Table borderless className="tableModal">
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <h5>{I18n.t('details.requestDetails')}</h5>
              <br></br>
              <tr>
                <th scope="row">
                  {I18n.t('requestDetails.credentialRequestId')}:
                </th>
                <td data-testid="type">{props.credential.id}</td>
              </tr>
              <tr>
                <th scope="row">{I18n.t('requestDetails.type')}:</th>
                <td data-testid="type">{props.credential.types}</td>
              </tr>
              <tr>
                <th scope="row">{I18n.t('requestDetails.receiverEmail')}:</th>
                <td data-testid="holder">
                  {isEditMode ? (
                    <Input
                      id="receiverEmail"
                      placeholder="Email"
                      name="email"
                      value={formValue.email}
                      onChange={handleChange}
                    />
                  ) : (
                    props.credential.holder
                  )}
                </td>
              </tr>
              <tr>
                <th scope="row">{I18n.t('requestDetails.requestedAt')}:</th>
                <td data-testid="requested-at">
                  {formatDate(props.credential.requestedAt)}
                </td>
              </tr>
              <tr>
                <th scope="row">{I18n.t('form.userLanguage')}:</th>
                <td data-testid="created-at">
                  {isEditMode ? (
                    <Input
                      id="language"
                      name="language"
                      type="select"
                      value={formValue.language}
                      onChange={handleChange}
                    >
                      <option value="">{I18n.t('form.chooseLanguage')}</option>
                      <option value="es">Español</option>
                      <option value="en">English</option>
                    </Input>
                  ) : (
                    props.credential.language
                  )}
                </td>
              </tr>
              <tr>
                <th scope="row">
                  {I18n.t('requestDetails.identityVerificationMethod')}:
                </th>
                <td data-testid="created-at">
                  {I18n.t(
                    `requestDetails.${formValue.identityVerificationMethod.type}`,
                  )}
                </td>
              </tr>
              <br></br>
              {formValue.identityVerificationMethod.type === 'F2F' && (
                <>
                  <h5>{I18n.t('requestDetails.userDetails')}</h5>
                  <br></br>
                  <tr>
                    <th scope="row">
                      {I18n.t('identityVerificationMethod.userName')}:
                    </th>
                    <td data-testid="userName">
                      {isEditMode ? (
                        <Input
                          id="userName"
                          name="userName"
                          value={
                            formValue.identityVerificationMethod.userData
                              ?.userName
                          }
                          onChange={handleChange}
                        />
                      ) : (
                        props.credential.identityVerificationMethod?.userData
                          ?.userName
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      {I18n.t('identityVerificationMethod.userLegalId')}:
                    </th>
                    <td data-testid="userLegalId">
                      {isEditMode ? (
                        <Input
                          id="userLegalId"
                          name="userLegalId"
                          value={
                            formValue.identityVerificationMethod.userData
                              ?.userLegalId
                          }
                          onChange={handleChange}
                        />
                      ) : (
                        props.credential.identityVerificationMethod?.userData
                          ?.userLegalId
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                  </tr>
                </>
              )}
              {formValue.identityVerificationMethod.type !== 'F2F' && (
                <>
                  <h5>Requested Info:</h5>

                  <tr>
                    <th scope="row">
                      {formValue.identityVerificationMethod.requestedInfo
                        ? transformCamelCase(
                            formValue.identityVerificationMethod
                              .requestedInfo![0].path,
                          )
                        : ''}
                      :{' '}
                    </th>
                    <td data-testid="requested-info-email">
                      {isEditMode ? (
                        <Input
                          id="requested-info-email"
                          name="requested-info-email"
                          value={
                            formValue.identityVerificationMethod.requestedInfo
                              ? formValue.identityVerificationMethod
                                  .requestedInfo[0].value
                              : ''
                          }
                          onChange={handleChange}
                        />
                      ) : formValue.identityVerificationMethod.requestedInfo ? (
                        formValue.identityVerificationMethod.requestedInfo[0]
                          .value
                      ) : (
                        ''
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                  </tr>
                </>
              )}
              <h5>{I18n.t('form.credentialSubject')}</h5>
              {displayPayload(props.credential.payload)}
            </tbody>
          </Table>
        )}
      </ModalBody>
      <ModalFooter>
        <div className="buttons-container">
          {approvedOrder ? (
            <Button
              className="buttonResendRight"
              disabled={loadingDocVerification}
              onClick={
                docStatus === 'Signed' || docStatus === 'Rejected'
                  ? closeModal
                  : goToCredentialsOrderList
              }
            >
              {docStatus === 'Signed' || docStatus === 'Rejected'
                ? I18n.t('actions.ok')
                : I18n.t('actions.accept')}
            </Button>
          ) : (
            <>
              {!showDevicesList ? (
                <Button
                  className={'buttonResend'}
                  onClick={() =>
                    setRejectedCredentialRequest(props.credential.id)
                  }
                  // disabled={
                  //   props.credential.status.toUpperCase() === 'APPROVED'
                  // }
                >
                  {I18n.t('actions.reject')}
                </Button>
              ) : null}
              {/*<Button*/}
              {/*  className={'buttonResend'}*/}
              {/*  onClick={() => setShowDevicesList(!showDevicesList)}*/}
              {/*  // disabled={props.credential.status.toUpperCase() === 'APPROVED'}*/}
              {/*>*/}
              {/*  {showDevicesList*/}
              {/*    ? I18n.t('details.previous')*/}
              {/*    : I18n.t('actions.approve')}*/}
              {/*</Button>*/}
              {isF2F && (
                <Button
                  className={'buttonResend'}
                  onClick={() => setShowDevicesList(!showDevicesList)}
                  // disabled={props.credential.status.toUpperCase() === 'APPROVED'}
                >
                  {showDevicesList
                    ? I18n.t('details.previous')
                    : I18n.t('actions.approve')}
                </Button>
              )}
              {!isF2F && (
                <Button
                  className={'buttonResend'}
                  onClick={updateCredentialWithoutDevice}
                  disabled={approvedOrder}
                >
                  {I18n.t('actions.approve')}
                </Button>
              )}
            </>
          )}
          {showDevicesList ? (
            <>
              <Button
                disabled={selectedDevice === ''}
                className="buttonResend"
                onClick={updateCredential}
              >
                {I18n.t('actions.sendToSign')}
              </Button>
            </>
          ) : null}
        </div>
        {errorMessage && (
          <Popup
            error
            message={errorMessage}
            onClose={() => setErrorMessage('')}
          />
        )}
        {successMessage && (
          <Popup
            success
            message={successMessage}
            onClose={() => {
              setSuccessMessage('');
              props.setIsOpen(false);
            }}
          />
        )}
        {rejectedCredentialRequest && (
          <Popup
            confirmation
            message={I18n.t('table.sureReject')}
            onConfirm={() => rejectCredential(rejectedCredentialRequest)}
            onReject={() => setRejectedCredentialRequest(null)}
            onClose={() => setRejectedCredentialRequest(null)}
          />
        )}
      </ModalFooter>
    </Modal>
  );
};
