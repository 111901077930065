import React, { useEffect, useState } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { CreateUserCredentialOrderDto } from '../../domain/createCredentialOrderDto';
import {
  createUserCredentialOrder,
  ParsedBackendError,
} from '../../services/vidcredentials';
import { useForm } from 'react-hook-form';
import { Popup } from '../Popup/Popup';
import closeIcon from '../../assets/closeIcon.png';
import { getLevelOfAssurances } from '../../services/levelOfAssurance';
import { LevelOfAssuranceDto } from '../../domain/levelOfAssurance';
import { LoATemplate } from '../LoATemplate/LoATemplate';
import { Entity } from '../../domain/entity';
import { getEntities } from '../../services/entities';
import { CredentialStudioTemplate } from '../CredentialStudioTemplate/CredentialStudioTemplate';
import { useRefreshToken } from '../../hooks/useRefreshToken';

import { I18n } from '../../i18n/i18n';

interface Props {
  isIssueUserCredentialOpen: boolean;
  setIsIssueUserCredentialOpen: (isIssueUserCredentialOpen: boolean) => void;
  loadTable: (rowsPerPage: number, page: number) => void;
  currentPage: number;
  tableSize: number;
}

const VIDCredentialStudioTypeId = '00000000-0000-0000-0000-000000000000';
const VIDCredentialStudioType = [
  { name: 'entity', fieldType: 'text' },
  {
    name: 'name',
    fieldType: 'text',
  },
  {
    name: 'role',
    fieldType: 'text',
  },
  {
    name: 'legalId',
    fieldType: 'text',
  },
];

export const IssueUserCredentialForm = (props: Props) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [levelOfAssurances, setLevelOfAssurance] = useState<
    LevelOfAssuranceDto[]
  >([]);
  const [levelOfAssuranceSelected, setLevelOfAssuranceSelected] = useState('');
  const [entities, setEntities] = useState<Entity[]>([]);
  const [entitySelected, setEntitySelected] = useState('');

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
  });

  const fetchData = async () => {
    const levelOfAssurances = await getLevelOfAssurances();
    setLevelOfAssurance(levelOfAssurances as LevelOfAssuranceDto[]);
    setLevelOfAssuranceSelected(
      levelOfAssurances.length > 0 ? levelOfAssurances[0].type : '',
    );

    const entities = await getEntities();

    setEntities(entities as Entity[]);
    setEntitySelected(entities.length > 0 ? entities[0].name : '');
  };

  const tokenProcess = useRefreshToken(fetchData);

  useEffect(() => {
    tokenProcess.refreshTokenProcess();
  }, []);

  const getLevelOfAssuranceValues = (inputList: any[]) => {
    const levelOfAssuranceValues: { path: string; value: string }[] = [];
    for (let index = 0; index < inputList.length; index++) {
      if (inputList[index].id.includes(levelOfAssuranceSelected)) {
        levelOfAssuranceValues.push({
          path:
            inputList[index].name === 'emailPath'
              ? 'email'
              : inputList[index].name,
          value: inputList[index].value,
        });
      }
    }
    return levelOfAssuranceValues;
  };

  const confirm = async (values: any, e: any) => {
    e.preventDefault();
    const email = e.target.receiverEmail.value;
    const credentialSubject: { [key: string]: number | string } =
      getCredentialSubject(e.target);

    credentialSubject.entity = entitySelected;

    const createUserCredentialOrderDto: CreateUserCredentialOrderDto = {
      email: email,
      payload: credentialSubject,
      identityVerificationMethod: {
        type: levelOfAssuranceSelected,
      },
    };
    if (levelOfAssuranceSelected !== 'F2F') {
      createUserCredentialOrderDto.identityVerificationMethod.requestedInfo =
        buildRequestedInfo(e.target);
    }
    if (levelOfAssuranceSelected === 'F2F') {
      createUserCredentialOrderDto.identityVerificationMethod.userData =
        buildUserData(e.target);
    }
    try {
      await createUserCredentialOrder(
        createUserCredentialOrderDto,
        values.language,
      );
      setSuccessMessage(I18n.t('form.credentialCreated'));
      setErrorMessage('');
    } catch (e) {
      setErrorMessage((e as ParsedBackendError).title);
      setSuccessMessage('');
    }
    resetForm(e);
  };

  const buildRequestedInfo = (inputList: any[]) => {
    const requestedInfo: { path: string; value: string }[] = [];
    for (let index = 0; index < inputList.length; index++) {
      if (inputList[index].id.includes(levelOfAssuranceSelected)) {
        requestedInfo.push({
          path:
            inputList[index].name === 'emailPath'
              ? 'email'
              : inputList[index].name,
          value: inputList[index].value,
        });
      }
    }
    return requestedInfo;
  };

  const buildUserData = (inputList: any[]) => {
    const userData: any = {};
    for (let index = 0; index < inputList.length; index++) {
      if (inputList[index].id.includes(levelOfAssuranceSelected)) {
        userData[inputList[index].name] = inputList[index].value as string;
      }
    }
    return userData;
  };
  const getCredentialSubject = (inputList: any[]) => {
    const credentialSubject: { [key: string]: number | string } = {};
    for (let index = 0; index < inputList.length; index++) {
      if (inputList[index].id.includes(VIDCredentialStudioTypeId)) {
        credentialSubject[inputList[index].name] = inputList[index].value;
      }
    }
    return credentialSubject;
  };

  const resetForm = async (e: any) => {
    e.target.receiverEmail.value = '';
    for (let index = 0; index < e.target.length; index++) {
      e.target[index].value = '';
    }
  };

  const { ref, ...rest } = register('originalEmail', {
    required: I18n.t('form.fillfield'),
    pattern: {
      value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
      message: I18n.t('form.provideValidEmail'),
    },
  });

  const { ref: languageRef, ...languageRest } = register('language', {
    required: I18n.t('form.fillfield'),
  });

  const closeModal = (e: any) => {
    e.preventDefault();
    props.setIsIssueUserCredentialOpen(false);
  };

  const handleLevelOfAssuranceChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setLevelOfAssuranceSelected(event.target.value);
  };

  const searchLevelOfAssuranceTemplate = (type: string) => {
    const levelOfAssurance = levelOfAssurances.find(
      (levelOfAssurance) => levelOfAssurance.type === type,
    );
    let fields: string[] = [];
    if (levelOfAssurance && levelOfAssurance.requestedInfoPaths) {
      fields = fields.concat(levelOfAssurance.requestedInfoPaths as string[]);
    }
    if (levelOfAssurance && levelOfAssurance.userDataFields) {
      fields = fields.concat(levelOfAssurance.userDataFields as string[]);
    }
    return fields;
  };

  return (
    <Modal
      isOpen={props.isIssueUserCredentialOpen}
      centered={true}
      fullscreen="lg"
      size="lg"
    >
      <ModalHeader
        close={
          <a href="#" onClick={(e) => closeModal(e)}>
            <img src={closeIcon} className="iconClose" />
          </a>
        }
        toggle={() => props.setIsIssueUserCredentialOpen(false)}
        className="modalHeader"
      >
        {I18n.t('form.createNewUser')}
      </ModalHeader>
      <ModalBody className="modalBody">
        <Form onSubmit={handleSubmit(confirm)} noValidate>
          <h1>{I18n.t('form.fill')}</h1>
          <FormGroup>
            <Label for="receiverEmail">{I18n.t('form.receiverEmail')}</Label>
            <Input
              id="receiverEmail"
              placeholder="Email"
              innerRef={ref}
              {...rest}
            />
            {errors.originalEmail && (
              <span className="error-span">{errors.originalEmail.message}</span>
            )}
          </FormGroup>
          <hr />
          <CredentialStudioTemplate
            credentialTypeId={VIDCredentialStudioTypeId}
            template={VIDCredentialStudioType}
            register={register}
            errors={errors}
            entities={entities}
            setEntitySelected={setEntitySelected}
          />
          <hr />
          <FormGroup>
            <Label for="levelOfAssurance">
              {I18n.t('form.identityVerification')}
            </Label>
            <Input
              id="levelOfAssurance"
              name="select"
              type="select"
              onChange={handleLevelOfAssuranceChange}
            >
              {levelOfAssurances.map((levelOfAssurance) => (
                <option
                  key={levelOfAssurance.type}
                  value={levelOfAssurance.type}
                >
                  {levelOfAssurance.type}
                </option>
              ))}
            </Input>
          </FormGroup>
          {levelOfAssuranceSelected ? (
            <LoATemplate
              type={levelOfAssuranceSelected}
              paths={searchLevelOfAssuranceTemplate(levelOfAssuranceSelected)}
              register={register}
              errors={errors}
            />
          ) : (
            <div>{I18n.t('form.loadingIdentity')}</div>
          )}
          <FormGroup>
            <Label for="language">{I18n.t('form.userLanguage')}</Label>
            <Input
              id="language"
              type="select"
              innerRef={languageRef}
              {...languageRest}
            >
              <option value="">{I18n.t('form.chooseLanguage')}</option>
              <option value="es">Español</option>
              <option value="en">English</option>
            </Input>
            {errors.language && (
              <span className="error-span">{errors.language.message}</span>
            )}
          </FormGroup>
          <Button className="buttonConfirm">{I18n.t('actions.confirm')}</Button>
          {errorMessage && (
            <Popup
              error
              message={errorMessage}
              onClose={() => setErrorMessage('')}
            />
          )}
          {successMessage && (
            <Popup
              success
              message={successMessage}
              onClose={() => {
                setSuccessMessage('');
                props.setIsIssueUserCredentialOpen(false);
                props.loadTable(props.tableSize, props.currentPage);
                reset();
                setEntitySelected(entities[0]?.name || '');
              }}
            />
          )}
        </Form>
      </ModalBody>
    </Modal>
  );
};
