import React, { useContext, useEffect, useState } from 'react';
import './Login.css';
import { OpenIDClient } from '../../services/openIDClient';
import { AuthContext } from '../../contexts/AuthContext';
import { I18n } from '../../i18n/i18n';

import logo from './../../assets/vidchain-logo-white.svg';
import google from './../../assets/google.svg';
import apple from './../../assets/apple.svg';
import hero from './../../assets/login-background.svg';
import { LanguageSwitch } from '../../components/LanguageSwitch/LanguageSwitch';
import { FeatureHub } from 'featurehub-javascript-client-sdk';
import { UserAuth } from '../../domain/userAuth';

export function Login() {
  const { setToken } = useContext(AuthContext);

  useEffect(() => {
    setToken('');
    const id = localStorage.getItem('intervalId');
    if (id) {
      clearInterval(id as string);
      localStorage.removeItem('intervalId');
    }
  }, []);

  const signInWithVIDchain = async () => {
    const client = OpenIDClient.getInstance().getLoginClient();
    client.wipeTokens();
    await client.callback();
    const token = await client.getToken({
      scopes: {
        request: ['openid', 'VIDCredentialStudio'],
      },
    });
    const user = new UserAuth(token);
    await FeatureHub.context
      .attributeValue('role', user.getEntityName().toLowerCase())
      .build();
    console.log('EntityName: ' + user.getEntityName());
    console.log('Token: ', token);
  };

  return (
    <>
      <div className="blue-login">
        <header>
          <span>
            <img src={logo} alt="Logo" />
          </span>
          <LanguageSwitch />
        </header>
        <section className="home-container">
          <div className="info-column">
            {/* <h6>By Validated ID</h6> */}
            <h1>{I18n.t('login.control')}</h1>
            <p>{I18n.t('login.with_vidchain')}</p>
            <div className="vid-chain-buttons-container">
              <button
                className="new-sign-in-vid-chain"
                onClick={() => signInWithVIDchain()}
              >
                <span>{I18n.t('login.login_with')}</span>
                <span>&rarr;</span>
              </button>
              <a
                className="new-sign-in-vid-chain"
                href="https://try.vidchain.net/demo/"
              >
                <span>{I18n.t('login.more_info')}</span>
                <span>&rarr;</span>
              </a>
              {/* <a className="sign-in-vid-chain" href={'vidchain://'}>
                Click here
              </a> */}
            </div>
          </div>
          <div className="background-column">
            <img src={hero} alt="Background" />
          </div>
        </section>
        <div className="download-container">
          <span>{I18n.t('login.get_it')}:</span>
          <div className="apps-container">
            <a
              href="https://play.google.com/store/apps/details?id=com.validatedid.wallet&hl=es_VE&gl=US"
              target="_blank"
            >
              <img src={google} alt="App" />
            </a>
            <a
              href="https://apps.apple.com/es/app/vidwallet/id1554340592"
              target="_blank"
            >
              <img src={apple} alt="App" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
