import { createContext, PropsWithChildren, useEffect, useState } from 'react';
import { I18n } from '../i18n/i18n';

export const AuthContext = createContext({
  accessToken: '',
  setToken: (_accessToken: string) => {
    return;
  },
  locale: '',
});

export const AuthContextProvider = ({ children }: PropsWithChildren<any>) => {
  const cachedLocale = localStorage.getItem('locale');
  const [locale, setLocale] = useState<string>(cachedLocale ?? 'en');
  const [accessToken, setToken] = useState<string>('');

  useEffect(() => {
    return I18n.onChange((newI18n) => setLocale(newI18n.locale));
  }, []);

  const { Provider } = AuthContext;

  return (
    <Provider
      value={{
        accessToken,
        setToken,
        locale,
      }}
    >
      {children}
    </Provider>
  );
};
