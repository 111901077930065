import { TablePagination } from '@mui/material';
import { TableSpecs } from '../../pages/Credentials/Credentials';
import { CredentialsFilters } from '../../contexts/CredentialsProviders/Providers/AllCredentialsContext';

import { convertToQueryString } from '../../pages/Credentials/CredentialDetailsModal/helpers/StringHelpers';
import { UserCredentialsFilters } from '../../contexts/CredentialsProviders/Providers/UserCredentialsContext';

interface CustomPaginationProps {
  loadCredentials: (
    tableSize: number,
    page: number,
    filterQuery?: string,
  ) => Promise<void>;
  tableSpecs: TableSpecs;
  filters?: CredentialsFilters | Partial<UserCredentialsFilters>;
}

export default function CustomPagination({
  loadCredentials,
  // TODO: use tableSpecs from context
  tableSpecs,
  filters,
}: CustomPaginationProps) {
  const queryParams = filters ? convertToQueryString(filters) : undefined;
  return (
    <TablePagination
      style={{ justifyContent: 'flex-end', display: 'flex' }}
      className="paginationContainer"
      count={tableSpecs.totalNumber}
      page={tableSpecs.currentPage - 1}
      rowsPerPage={tableSpecs.tableSize}
      onPageChange={async (_, nextPage) => {
        await loadCredentials(tableSpecs.tableSize, nextPage + 1, queryParams);
      }}
      onRowsPerPageChange={async (event) => {
        await loadCredentials(
          +event.target.value,
          tableSpecs.currentPage,
          queryParams,
        );
      }}
      showFirstButton
      showLastButton
    />
  );
}
