import { InputProps, InputType } from 'reactstrap/types/lib/Input';
import React, { Fragment } from 'react';
import { Button, ButtonProps, Input, InputGroup } from 'reactstrap';
import { I18n } from '../../i18n/i18n';
import './FormBuilder.css';

export interface SelectInputOption {
  translationKey: string;
  value: any;
}

export class FormBuilder {
  private readonly inputEntries: JSX.Element[];
  private isLastStepCalled: boolean;

  constructor() {
    this.inputEntries = [];
    this.isLastStepCalled = false;
  }

  private insertOptions(data: SelectInputOption[]) {
    return data.map(({ translationKey, value }, index) => (
      <option key={`option-${value}-${index}`} value={value}>
        {value
          ? I18n.t(translationKey, { defaultValue: translationKey })
          : `--${I18n.t(translationKey, { defaultValue: translationKey })}--`}
      </option>
    ));
  }

  private wrapWithLabel(children: JSX.Element, label: string) {
    return (
      <div className="input-datepicker-advanced-search-bar">
        <label>{I18n.t(label, { defaultValue: label })}</label>
        {children}
      </div>
    );
  }

  addInput(
    type: InputType,
    value: string,
    name: string,
    onChange: React.ChangeEventHandler<HTMLInputElement>,
    inputOptions: Omit<InputProps, 'value' | 'type' | 'onChange'> & {
      selectInputOptions?: SelectInputOption[];
    } = { containerClassName: '', selectInputOptions: [] },
  ) {
    const {
      containerClassName,
      selectInputOptions,
      placeholder,
      label,
      ...inputProps
    } = inputOptions;

    if (this.isLastStepCalled) {
      throw Error("Can't add entry after button has been inserted ");
    }

    const input = (
      <Input
        value={value}
        type={type}
        name={name}
        onChange={onChange}
        placeholder={I18n.t(placeholder ?? '', {
          defaultValue: placeholder,
        })}
        {...inputProps}
      >
        {type === 'select'
          ? this.insertOptions(selectInputOptions ?? [])
          : null}
      </Input>
    );

    this.inputEntries.push(
      <InputGroup
        key={`form-entry-${this.inputEntries.length}`}
        className="input-finder-advanced-search-bar"
      >
        {label ? this.wrapWithLabel(input, label) : input}
      </InputGroup>,
    );
  }

  addButton(
    translationKey: string,
    onClick: React.MouseEventHandler<HTMLButtonElement>,
    buttonOptions?: Omit<ButtonProps, 'variant' | 'onClick' | 'type'>,
  ) {
    this.isLastStepCalled = true;
    this.inputEntries.push(
      <Button
        type="button"
        key={`form-button-${this.inputEntries.length}`}
        variant="secondary"
        onClick={onClick}
        {...buttonOptions}
      >
        {I18n.t(translationKey)}
      </Button>,
    );
  }

  getJSX(): JSX.Element {
    if (!this.isLastStepCalled) {
      throw Error('addButton has not been called');
    }
    return <Fragment>{this.inputEntries}</Fragment>;
  }
}
