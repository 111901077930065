import React, { useEffect, useState } from 'react';
import {
  FieldErrors,
  FieldValues,
  RegisterOptions,
  UseFormRegister,
} from 'react-hook-form';
import { FormGroup, Input, Label } from 'reactstrap';
import { I18n } from '../../i18n/i18n';
import './LoATemplate.css';
import { Device } from '../../domain/devices';

interface Props {
  type: string;
  paths: string[];
  register: UseFormRegister<FieldValues>;
  devicesList?: Device[];
  errors: FieldErrors<FieldValues>;
}

export const LoATemplate = (props: Props) => {
  const { errors, register } = props;

  return (
    <FormGroup>
      {React.Children.toArray(
        props.paths.map((path) => {
          let fieldName = path;
          let registerOptions: RegisterOptions = {
            required: I18n.t('form.fillfield'),
          };
          if (path === 'email') {
            fieldName = 'emailPath';
            registerOptions = {
              required: I18n.t('form.fillfield'),
              pattern: {
                value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                message: I18n.t('form.provideValidEmail'),
              },
            };
          }
          if (path === 'personalNumber') {
            registerOptions = {
              required: I18n.t('form.fillfield'),
              pattern: {
                value: /^[a-zA-Z0-9]+$/,
                message: I18n.t('form.provideValidNumber'),
              },
            };
          }

          const { ref, ...rest } = register(fieldName, registerOptions);

          return (
            <>
              {path !== 'deviceId' && (
                <Input
                  key={path}
                  className="pathField"
                  id={props.type}
                  placeholder={path}
                  type="text"
                  innerRef={ref}
                  {...rest}
                />
              )}

              {path === 'deviceId' &&
                props.devicesList &&
                props.devicesList.length > 0 && (
                  <>
                    <Label>{I18n.t('details.selectDevice')}</Label>
                    <Input
                      key={path}
                      className="pathField"
                      id={props.type}
                      type="select"
                      innerRef={ref}
                      {...rest}
                    >
                      {props.devicesList.map((device) => {
                        return (
                          <option key={device.deviceId} value={device.deviceId}>
                            {device.deviceName}
                          </option>
                        );
                      })}
                    </Input>
                  </>
                )}

              {errors[fieldName] && (
                <span className="error-span">{errors[fieldName]?.message}</span>
              )}
            </>
          );
        }),
      )}
    </FormGroup>
  );
};
